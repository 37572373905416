<template>
  <validation-observer ref="form" v-slot="{ invalid }" slim>
    <layout width="540">
      <template slot="header">
        Program settings
      </template>

      <template>
        <v-form>
          <form-subheader title="Map" />
          <v-select
            v-model="state.programMap.value"
            :items="maps"
            label="Default map"
            item-text="name"
            item-value="id"
          />

          <form-subheader title="Style" />
          <v-select v-model="theme" :items="themes" label="Theme" disabled />
          <v-select
            v-model="state.programSizeOfObjectsOnMap.value"
            disabled
            :items="sizes"
            label="Size of objects on map"
          />
          <v-select
            v-model="state.programSizeOfObjectCardOnMap.value"
            disabled
            :items="sizes"
            label="Size of object card on map"
          />
          <v-select
            v-model="language"
            :items="languages"
            label="Language"
            disabled
          />
          <form-switcher
            v-model="state.programShowCoreTime.value"
            title="Show core time"
          />

          <form-subheader title="Content" />

          <v-select
            v-model="state.programDefaultGeotag.value"
            :items="geotags"
            item-text="name"
            item-value="id"
            clearable
            label="Geotag"
          />
          <v-select
            v-model="state.programDefaultObjectGroup.value"
            :items="objectGroups"
            item-text="name"
            item-value="id"
            clearable
            label="Group"
          />

          <form-switcher
            v-model="state.programAllowAllGeotags.value"
            title="Allow ALL"
          />
          <div class="caption mb-1 mt-1">
            If you disable the ALL option, only objects and plans that have been
            added to geotags will remain available to you.
          </div>

          <form-subheader title="Default position" />

          <validation-provider
            v-slot="{ errors }"
            name="Center latitude"
            rules="double"
          >
            <v-text-field
              v-model="state.programDefaultPosition.value[1]"
              :error-messages="errors"
              label="Center latitude"
              clearable
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Center longitude"
            rules="double"
          >
            <v-text-field
              v-model="state.programDefaultPosition.value[0]"
              :error-messages="errors"
              label="Center longitude"
              clearable
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Default scale"
            rules="double"
          >
            <v-text-field
              v-model="state.programDefaultRotation.value"
              :error-messages="errors"
              label="Rotation(deg)"
            />
          </validation-provider>
          <form-subheader title="Default scale" />

          <v-slider
            v-model="state.programDefaultScale.value"
            thumb-label
            max="28"
            min="0"
            type="number"
            append-icon="mdi-magnify-plus-outline"
            prepend-icon="mdi-magnify-minus-outline"
            @change="handleZoom"
          />
          <v-btn outlined color="primary" block @click="setCurrentMap">
            Use current map parameters
          </v-btn>
          <v-btn
            outlined
            color="primary"
            class="mt-4"
            block
            @click="setDefault"
          >
            Set default parameters
          </v-btn>
        </v-form>
      </template>
      <template slot="footer">
        <v-spacer />
        <v-btn text color="text-primary" @click.stop="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          depressed
          :loading="isLoading"
          :disabled="isLoading || invalid"
          @click="submit"
        >
          Save
        </v-btn>
      </template>
    </layout>
  </validation-observer>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { useProgramSettings } from '@/modules/account/useProgramSettings';
import useGeneralSettings from '@/modules/settings/general/useGeneralSettings';
import { useMap } from '@/compositions/map';
import { onMounted, ref } from '@vue/composition-api';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { useObjectGroups } from '@/modules/object-groups/compositions/object-groups';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { double } from 'vee-validate/dist/rules';
import { cloneDeep } from 'lodash-es';
import { typeCast } from '@/provider/utils';

extend('double', double);

export default {
  name: 'ProgramSettings',
  components: {
    Layout,
    ValidationObserver,
    ValidationProvider
  },
  setup(props, context) {
    const {
      getCenter,
      setZoom,
      mapZoomIn,
      mapZoomOut,
      getZoom,
      setDefaultCenter,
      setDefaultZoom
    } = useMap();
    const {
      programSettings,
      centerPosition,
      load,
      update,
      maps,
      themes,
      theme,
      sizes,
      languages,
      language
    } = useProgramSettings();
    const generalSettings = useGeneralSettings(props, context);
    const { list: geotags } = useGeotags();
    const { objectGroupList: objectGroups } = useObjectGroups();
    const isLoading = ref(false);
    const state = ref({
      programMap: {
        id: '',
        value: ''
      },
      programSizeOfObjectsOnMap: {
        id: '',
        value: ''
      },
      programSizeOfObjectCardOnMap: {
        id: '',
        value: ''
      },
      programShowCoreTime: {
        id: '',
        value: false
      },
      programDefaultGeotag: {
        id: '',
        value: ''
      },
      programDefaultObjectGroup: {
        id: '',
        value: ''
      },
      programAllowAllGeotags: {
        id: '',
        value: true
      },
      programDefaultPosition: {
        id: '',
        value: [0, 0]
      },
      programDefaultRotation: {
        id: '',
        value: 0
      },
      programDefaultScale: {
        id: '',
        value: 20
      }
    });

    const zoomIn = () => {
      mapZoomIn();
      state.value.programDefaultScale.value = getZoom();
    };
    const zoomOut = () => {
      mapZoomOut();
      state.value.programDefaultScale.value = getZoom();
    };

    const setCurrentMap = () => {
      handleGetCenter();
      handleGetZoom();
    };

    const setDefault = () => {
      const defaultCoords = generalSettings.getById('programDefaultPosition')
        .defaultValue;
      const defaultZoom = generalSettings.getById('programDefaultScale')
        .defaultValue;
      state.value.programDefaultScale.value = defaultZoom;
      state.value.programDefaultPosition.value = defaultCoords;

      setDefaultCenter(defaultCoords);
      handleZoom(defaultZoom);
    };

    const handleGetCenter = () => {
      let coords = getCenter();
      centerPosition.value = coords;
      state.value.programDefaultPosition.value = coords.map(item =>
        item.toFixed(6)
      );
    };

    const handleGetZoom = () => {
      state.value.programDefaultScale.value = getZoom();
    };

    const handleZoom = value => {
      setZoom(value);
    };

    onMounted(async () => {
      await load();
      Object.keys(state.value).forEach(key => {
        state.value[key] = cloneDeep(programSettings.value[key]);
      });
    });

    const submit = async () => {
      try {
        isLoading.value = true;
        const defaultCoords = state.value.programDefaultPosition.value;
        const defaultZoom = state.value.programDefaultScale.value;

        setDefaultCenter(defaultCoords, true);
        setDefaultZoom(defaultZoom, true);

        const settings = Object.keys(state.value).map(key => {
          let value;
          if (key === 'programDefaultPosition') {
            value = state.value[key].value.slice(0, 2).join(',');
          } else {
            value = typeCast(state.value[key].type, state.value[key].value);
          }

          return {
            id: state.value[key].id,
            value
          };
        });

        await update(settings);
        context.emit('close');
      } finally {
        isLoading.value = false;
      }
    };
    return {
      maps,
      themes,
      theme,
      sizes,
      languages,
      language,
      zoomIn,
      zoomOut,
      handleGetCenter,
      handleZoom,
      handleGetZoom,
      setCurrentMap,
      setDefault,
      submit,
      objectGroups,
      geotags,
      isLoading,
      state
    };
  }
};
</script>
